<template>
  <div class="outer-wrapper">
    <div class="top-wrapper">
      <div class="description-outer-wrapper">
        <div class="description-wrapper">
          <div class="city-logo">
            <img :src="cityUrl" class="city-logo-img" />
          </div>
          <div class="title-wrapper">
            <div class="title-text-wrapper">
              <span :style="{color:'#444'}">
                <Icon type="md-subway" :size="26" />
                {{content.line_name}}
              </span>
              <div class="title-down-line" :style="{backgroundColor:content.line_color}"></div>
            </div>
          </div>
          <div class="d-title-wrapper">
            <Divider orientation="left">
              <span>概况</span>
            </Divider>
          </div>
          <div class="d-content-wrapper">
            <p class="d-content-p">{{content.description}}</p>
          </div>
        </div>
        <div class="line-img-wrapper">
          <img class="line-img" :src="getImgUrl('lineDes',content.line_code,'jpg')" alt="图片暂无" />
        </div>
      </div>
    </div>
    <div class="down-wrapper">
      <div class="down-inner-wrapper">
        <el-tabs type="border-card" id="pane-anchor">
          <el-tab-pane>
            <span slot="label" @click="goAnchor('#pane-anchor')">
              <i class="el-icon-date"></i> 线路详情
            </span>
            <div class="com-wrapper">
              <div class="t-title">基本信息：</div>
              <table class="props-table">
                <tr>
                  <td class="title-td">轨道类型：</td>
                  <td>{{content.track_style}}</td>
                  <td class="title-td">标识颜色：</td>
                  <td>
                    <Icon type="ios-flag" :style="{color:content.line_color}" :size="30" />
                  </td>
                </tr>
                <tr>
                  <td class="title-td">建设期数：</td>
                  <td>{{content.term}}</td>
                  <td class="title-td">开通日期：</td>
                  <td>{{content.open_date?(content.open_date.split(' ')[0]):""}}</td>
                </tr>
                <tr>
                  <td class="title-td">里程(km)：</td>
                  <td>{{content.length}}</td>
                  <td class="title-td">车站数：</td>
                  <td>{{content.station_amount}}</td>
                </tr>
                <tr>
                  <td class="title-td">车型：</td>
                  <td>{{content.carriage_style}}</td>
                  <td class="title-td">配车数：</td>
                  <td>{{content.carriage_amount}}</td>
                </tr>
                <tr>
                  <td class="title-td">供电方式：</td>
                  <td>{{content.power_style}}</td>
                  <td class="title-td">最高时速：</td>
                  <td>{{content.max_speed}}KM/H</td>
                </tr>
                <tr>
                  <td class="title-td">起止站点：</td>
                  <td>{{content.start_station+'-'+content.end_station}}</td>
                  <td class="title-td">运营时间：</td>
                  <td>{{content.start_time+'-'+content.end_time}}</td>
                </tr>
                <tr>
                  <td class="title-td">最大埋深：</td>
                  <td>{{content.max_depth}}</td>
                  <td class="title-td"></td>
                  <td></td>
                </tr>
              </table>
            </div>

            <div class="com-wrapper">
              <div class="t-title">运维公司一览：</div>
              <ul>
                <li>
                  <span class="title_name">运营公司：</span>
                  <span class="text">{{content.operation_company}}</span>
                </li>
                <template v-if="content.maintenance_company">
                <li v-show="JSON.parse(content.maintenance_company).carriage">
                  <span class="title_name">车辆维保：</span>
                  <span class="text">{{JSON.parse(content.maintenance_company).carriage}}</span>
                </li>
                <li v-show="JSON.parse(content.maintenance_company).power">
                  <span class="title_name">供电维保：</span>
                  <span class="text">{{JSON.parse(content.maintenance_company).power}}</span>
                </li>
                <li v-show="JSON.parse(content.maintenance_company).communication">
                  <span class="title_name">通号维保：</span>
                  <span class="text">{{JSON.parse(content.maintenance_company).communication}}</span>
                </li>
                <li v-show="JSON.parse(content.maintenance_company).work">
                  <span class="title_name">工务维保：</span>
                  <span class="text">{{JSON.parse(content.maintenance_company).work}}</span>
                </li>
                </template>
              </ul>
              <div class="bottom-label">
                <a href></a>
              </div>
            </div>
            <div class="com-wrapper">
              <div class="t-title">最新安评简要：</div>
              <div class="report-summary-wrapper">
                <span v-if="!content.report_conclusion">暂无</span>
                <pre v-else>{{content.report_conclusion.replaceAll('\n','')}}</pre>
              </div>
            </div>
            <div class="com-wrapper">
              <div class="t-title">安评报告下载：</div>
              <div class="download-wrapper">
              <div class="download-inner-wrapper">
                <!-- <span style="margin-right:5px;"><Icon type="md-cloud-download" :size="20"/></span> -->
                <a :href="'/conclusion/report_c/'+content.line_code">点击进入{{content.line_name}}安评报告下载页面</a>
              </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane>
            <span slot="label" @click="goAnchor('#pane-anchor')">
              <Icon type="ios-nuclear-outline" /> 风险分析
            </span>
            <div class="total-chart-wrapper">
              <div class="more_to">
                <router-link :to="{name:'risk_l'}">点击查看更多风险点数据&nbsp;>></router-link>
              </div>
              <div class="single-chart-wrapper">
                <degree-level
                  :choosedOptions="choosedOptions"
                  :drawAgain="updateCount"
                  :esize="echartSize"
                ></degree-level>
              </div>
              <div class="single-chart-wrapper">
                <correct-stat
                  :choosedOptions="choosedOptions"
                  :drawAgain="updateCount"
                  :esize="echartSize"
                ></correct-stat>
              </div>
              <div class="single-chart-wrapper">
                <major-system
                  :choosedOptions="choosedOptions"
                  :drawAgain="updateCount"
                  :esize="echartSize"
                ></major-system>
              </div>
              <div class="single-chart-wrapper">
                <risk-sort
                  :choosedOptions="choosedOptions"
                  :drawAgain="updateCount"
                  :esize="echartSize"
                ></risk-sort>
              </div>
              <div class="single-chart-wrapper">
                <correct-demand
                  :choosedOptions="choosedOptions"
                  :drawAgain="updateCount"
                  :esize="echartSize"
                ></correct-demand>
              </div>
            </div>
          </el-tab-pane>
          <!--
                    <el-tab-pane><span slot="label" class="pane_label" @click="goAnchor('#pane-anchor')">
                            <Icon type="md-clipboard" />客流统计</span>
                        <div class="ins-wrapper">
                            <img class='flow-img' :src="getImgUrl('passengerFlow',content.line_code,'png')" alt='暂无' />
                        </div>
                    </el-tab-pane>

                    <el-tab-pane> <span slot="label"><i class="el-icon-date"></i> 安评</span>
                        <div>
                            <evaluate-tab></evaluate-tab>

                        </div>
                    </el-tab-pane>
                    <el-tab-pane> <span slot="label"><i class="el-icon-date"></i> 客流</span>
                        <div>
                            <chart-bar-passenger-flow-tab title_text="客流年度趋势图" :chart_draw_again="chart_draw_again" key='3'></chart-bar-passenger-flow-tab>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane> <span slot="label"><i class="el-icon-date"></i> 故障历史</span>
                        <div>
                            <accident-tab></accident-tab>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane> <span slot="label"><i class="el-icon-date"></i> 设备供应商</span>
                        <div>
                            <supplier-tab></supplier-tab>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane> <span slot="label"><i class="el-icon-date"></i> 采购历史</span>
                        <div>
                            <purchase-tab></purchase-tab>
                        </div>
          </el-tab-pane>-->
        </el-tabs>
      </div>
    </div>
  </div>
</template>
<script>
import degreeLevel from "@/components/RiskResult/Risk/Charts/DegreeLevel.vue";
import correctStat from "@/components/RiskResult/Risk/Charts/CorrectStat.vue";
import riskSort from "@/components/RiskResult/Risk/Charts/RiskSort.vue";
import majorSystem from "@/components/RiskResult/Risk/Charts/MajorSystem.vue";
import correctDemand from "@/components/RiskResult/Risk/Charts/CorrectDemand.vue";
/*import chartBarPassengerFlowTab from './ChartBarPassengerFlowTab.vue'
    import supplierTab from './SupplierTab.vue'
    import evaluateTab from './EvaluateTab.vue'
    import accidentTab from './AccidentTab.vue'
    import purchaseTab from './PurchaseTab.vue'*/
import reportTxt from "./LineReportTxt.js";
let userToken = localStorage.getItem("current_user_token");
export default {
  name: "lineContent",
  components: {
    degreeLevel,
    correctStat,
    riskSort,
    majorSystem,
    correctDemand
    /*chartBarPassengerFlowTab,
            supplierTab,
            evaluateTab,
            accidentTab,
            purchaseTab*/
  },
  data() {
    return {
      content: {},
      download_content: [],
      content_other: [],
      choosedOptions: {
        selectedList: [],
        keyword: ""
      },
      updateCount: 1,
      echartSize: { height: "450px", width: "550px" }
    };
  },
  computed: {
    cityUrl: function() {
      return process.env.BASE_URL + "img/citylogo.jpg";
    },
    line_report_summary: function() {
      return reportTxt[this.content.line_code];
    }
  },
  props: {
    line_code: {
      type: String,
      default: "18sh07"
    }
  },
  created() {
    this.get_data();
  },
  beforeRouteUpdate(to, from, next) {
    this.get_data(to.params.line_code);
    next();
  },
  methods: {
    get_data(id = null) {
      if (id === null) {
        id = this.line_code;
      }
      this.$axios
        .post(this.$url + "/line_content", {
          line_code: id,
          userToken: userToken
        })
        .then(
          response => {
            this.content = response.data.res_record;
            this.choosedOptions.selectedList.push({
              key_name: "line_code",
              value: id,
              text:this.content.line_name
            });
            this.updateCount++;
        }
        );
    },
    getImgUrl(dir, id, form) {
      return process.env.BASE_URL + "img/" + dir + "/" + id + "." + form;
    },
    goAnchor(selector) {
      this.$el.querySelector(selector).scrollIntoView();
    }
  }
};
</script>
<style scoped>
pre {
  white-space: pre-wrap;
  /* css3.0 */
  white-space: -moz-pre-wrap;
  /* Firefox */
  white-space: -pre-wrap;
  /* Opera 4-6 */
  white-space: -o-pre-wrap;
  /* Opera 7 */
  word-wrap: break-word;
  /* Internet Explorer 5.5+ */
}

.title-text-wrapper {
  font-size: 20px;
  width: 200px;
  margin: 50px auto;
  padding: 10px 30px;
  border-radius: 5px;
  color: #777;
}

.title-wrapper {
  text-align: center;
  height: 100px;
}

.city-text-wrapper {
  padding-right: 10px;
  font-size: 20px;
  font-weight: 400;
  color: #5a5a5a;
  font-family: STLiti;
  margin-right: 20px;
}

.description-wrapper {
  position: relative;
  width: 60%;
  float: left;
}

.description-outer-wrapper {
  padding: 20px 50px;
  overflow: hidden;
}

.line-img-wrapper {
  float: right;
  width: 40%;
  text-align: center;
  padding-top: 100px;
}

.line-img {
  width: 430px;
}

.d-content-wrapper {
  padding: 20px 40px 0 40px;
}

.d-content-p {
  font-size: 18px;
  color: #888;
  text-indent: 2em;
  line-height: 40px;
}

.props-table-wrapper {
  padding: 30px;
  font-size: 18px;
}

.props-table {
  width: 100%;
  text-align: center;
  color: #5a5a5a;
}

.title-td {
  color: #aaa;
  font-size: 16px;
  text-align: right;
  height: 40px;
  line-height: 40px;
}

.down-inner-wrapper {
  padding: 50px;
}

.com-table {
  width: 100%;
}

.com-wrapper {
  padding: 10px 50px;
  font-size: 16px;
  color: #5a5a5a;
  width: 80%;
  margin: 0 auto;
}

.t-title {
  font-size: 16px;
  padding: 10px 0px;
  margin-left: -50px;
  margin-bottom: 20px;
  border-bottom: 1px dashed #bbb;
  font-family: SimHei, Microsoft YaHei;
}

ul {
  list-style: none;
}

li {
  height: 40px;
  line-height: 40px;
}

.title_name {
  font-size: 14px;
  color: #aaa;
  width: 150px;
  display: inline-block;
}

.text {
  font-size: 16px;
  color: #5a5a5a;
  margin-left: 50px;
}

.bottom-label {
  padding: 10px 0;
  font-size: 14px;
  margin-top: 20px;
  border-top: 1px dashed #bbb;
  font-family: SimHei, Microsoft YaHei;
  text-align: right;
}

.more_to {
  text-align: right;
  font-size: 14px;
  padding: 5px 8px 5px 0;
}

.report-summary-wrapper {
  line-height: 2em;
  padding-right: 3em;
}

.download-wrapper {
  padding: 30px 50px;
  text-align: center;
}

.download-item {
  margin: 0 30px;
}

.single-chart-wrapper {
  display: inline-block;
  margin: 5px;
}

.total-chart-wrapper {
  margin: 0 auto;
  text-align: center;
  overflow: hidden;
}

.ins-wrapper {
  text-align: center;
  padding: 50px 0;
}

.flow-img {
  width: 600px;
}

.city-logo {
  position: absolute;
  top: 10px;
  left: 10px;
}
.city-logo-img {
  width: 120px;
}

.title-down-line {
  height: 3px;
  background-color: #5a5a5a;
  margin-top: 10px;
}

.download-inner-wrapper{
  background-color:#993333;
  color:#fff;
  padding: 15px 30px;
  width:fit-content;
  display: inline;
}

pre{
  text-indent: 32px;
  text-align: justify;
}
</style>