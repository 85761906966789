let userToken = localStorage.getItem("current_user_token");
export const getExcel = {
    methods:{
      excelGo(value,title=[]){
        this.$axios
          .post(this.$url + "/chart_excel", {
            userToken: userToken,
            askData: "chartExcel",
            data: value,
            title:title
          })
          .then(response => {
            if (response.data.stat === "ok") {
              window.open(this.$static_url+response.data.file_url);
              this.update_count++;
            } else {
              console.log('error');
            }
          });
      },
    }
}