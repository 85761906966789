export const chartText = {
    methods:{
        selectedListTextFormat(value) {
            let temp = "";
            for (let i of value) {
              temp += "(" + i.text + ")";
            }
            return temp;
          },
    }
}