
let array_equals = function (array1, array2) {
    if (!array2 || !array1)
        return false;
    if (array1.length != array2.length)
        return false;

    for (var i = 0, l = array1.length; i < l; i++) {
        if (array1[i] instanceof Array && array2[i] instanceof Array) {
            if (!array_equals(array1[i], array2[i]))
                return false;
        }
        else if (array1[i] != array2[i]) {
            return false;
        }
    }
    return true;
}

let object_equals = function (object1, object2) {
    for (let propName in object1) {
        if (Object.prototype.hasOwnProperty.call(object1,propName) != Object.prototype.hasOwnProperty.call(object2,propName)) {
            return false;
        }
        else if (typeof object1[propName] != typeof object2[propName]) {
            return false;
        }
    }
    for (let propName in object2) {
        if (Object.prototype.hasOwnProperty.call(object1,propName) != Object.prototype.hasOwnProperty.call(object2,propName)) {
            return false;
        }
        else if (typeof object1[propName] != typeof object2[propName]) {
            return false;
        }
        if (!Object.prototype.hasOwnProperty.call(object1,propName))
            continue;
        if (object1[propName] instanceof Array && object2[propName] instanceof Array) {
            if (!array_equals(object1[propName], object2[propName]))
                return false;
        }
        else if (object1[propName] instanceof Object && object2[propName] instanceof Object) {
            if (!object_equals(object1[propName], object2[propName]))
                return false;
        }
        else if (object1[propName] != object2[propName]) {
            return false;
        }
    }
    return true;
}

Array.prototype.indexOfObject = function (item) {
    for (let key in this) {
        if (object_equals(this[key], item)) {
            return key;
        }
    }
    return -1;
}
Object.defineProperty(Array.prototype, "indexOfObject", { enumerable: false });

let CounterAmount = function(oridata, key) {
    let arda = {};
    for (let ele of oridata) {
        if (ele[key] in arda) {
            arda[ele[key]]++;

        } else {
            arda[ele[key]] = 1;

        }
    }
    return arda;
}

export{CounterAmount}
